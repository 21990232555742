export const environment = {
			production: false,
			apiEnvironment: 'Dev',
			apiRoot: 'https://api.trbportal.com',
			nodeApiRoot: 'https://api.march5.gmesta.sandbox.portal.trb.bank',
			cognitoRegion: 'us-east-1',
			cognitoUserPoolId: 'us-east-1_DqiwKmEQy',
			cognitoAppClientId: '5eckde4ksaj7llppbojpbchda5',
			webSocketApiUri: 'wss://websocket.march5.gmesta.sandbox.internal.trb.bank/',
			clientAppUri: 'https://portal5.gmesta.sandbox.portal.trb.bank',
			extIdentityProviderRoot: 'https://api.idp.march5.gmesta.sandbox.portal.trb.bank',
			plaidVerificationTemplate: 'idvtmp_51U3nsbLGA96V7',
			plaidEnv: 'sandbox',
			consoleLogLevel: 'DEBUG'
		};
		